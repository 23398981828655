.dialog-container {
	display: flex;
	width: 420px;
	min-width: 320px;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
	padding: 16px;

	border-radius: 0;
	/* border: 1px solid rgba(0, 0, 0, 0.16); */
	background: rgba(0, 0, 0, 1);
	/* surface */
	/* box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25); */
}

.dialog-container h2 {
	padding: 0;
	height: 19px;
}

.confirm-modal-title {
	color: rgba(255, 255, 255, 0.9);
	font-family: Inter !important;
	font-size: 16px !important;
	font-style: normal !important;
	font-weight: 700 !important;
	line-height: normal !important;
	display: flex;
	align-items: center;
}

.confirm-modal-message {
	color: rgba(255, 255, 255, 0.9) !important;

	font-family: Inter !important;
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: normal !important;
}

.actions-class {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 8px;
	align-self: stretch;
	border-top: 1px solid #000;
	padding: 0 !important;
	margin-top: 16px;
}

.confirm-button {
	font-family: Inter !important;
	width: 100%;
	text-transform: none !important;
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: normal !important;
	height: 31px !important;
	padding: 6px 12px 8px 12px !important;
	text-align: left !important;
}

.cancel-button {
	font-family: Inter !important;
	width: 100%;
	color: rgba(255, 255, 255, 0.7) !important;
	border-radius: 5px !important;
	border: 1px solid rgba(255, 255, 255, 0.2) !important;
	margin-left: 0px !important;

	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: normal !important;
}
